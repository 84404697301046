const theme_list_section = document.querySelector(".theme-list-section");
const footer_detail = document.querySelector("footer");
const big_wrapper = document.querySelector(".main-big-wrapper");
const videos = document.querySelectorAll("video");
const iframes = document.querySelectorAll("iframe");
const webcam = document.querySelector('[href="/baustellenkamera"]');

let maxScroll = null;

//inner debug window:
const debugContainer = document.createElement("div");
debugContainer.setAttribute("id", "debugContainer");
debugContainer.style.cssText =
	"position: fixed; z-index: 999999; top:0; width: 100%; min-height: 100px; max-height: 200px; overflow:scroll; background: #000; color: #ff00ff";
const debugList = document.createElement("ul");
debugList.setAttribute("id", "debugList");
debugContainer.appendChild(debugList);
document.body.appendChild(debugContainer);
document.getElementById("debugContainer").hidden = true;

let debugActive = false;

//const interactive_map = document.querySelector(
//	'[href="/interaktive-karte"]'
//).parentElement;

let overlayActive = false;
let trainStatus = false;

const urlParams = new URLSearchParams(location.search);

function init_device_handler() {
	if (!tabletInStorage()) {
		if (detectInternDevice()) {
			localStorage.setItem("tablet", true);
		} else {
			//not the tablet
			document.addEventListener(
				"touchstart",
				(e) => {
					if (e.touches.length > 5) {
						localStorage.setItem("tablet", true);
						location.reload();
					}
				},
				false
			);
			/* var e = new Event("touchstart");
			e.touches = [
				{ pageX: 100, pageY: 100 },
				{ pageX: 101, pageY: 101 },
				{ pageX: 99, pageY: 99 },
				{ pageX: 102, pageY: 102 },
				{ pageX: 103, pageY: 103 },
				{ pageX: 104, pageY: 104 },
			];
			document.dispatchEvent(e); */
		}
	}

	if (tabletInStorage()) {
		document.addEventListener(
			"DOMContentLoaded",
			() => {
				//inhalte sollten erst eingeblendet werden wenn rotiert wurde.
				document.body.classList.add("rotate180");
				document.documentElement.style.scrollBehavior = "auto";
				window.scrollTo({
					top: document.body.scrollHeight, //buggy auf themenübersicht!
					behaviour: "instant",
				});

				/* to fix scroll by tablets only (subpages need this) //location.pathname !== "/" */
				if (document.querySelector(".breadcrumb-wrapper")) {
					maxScroll = window.pageYOffset; // schwierig vlt wenn gar nicht gescroltl werden kannn
					console.log("this is maxScroll: " + maxScroll);
					/*  add resize listener for rescaling overwrite*/
					window.addEventListener("resize", () => {
						window.scrollTo({
							top: document.body.scrollHeight,
							behaviour: "instant",
						});
						maxScroll = window.pageYOffset; // schwierig vlt wenn gar nicht gescroltl werden kannn
						//console.log("new maxScroll: " + maxScroll);
					});
				}

				setTimeout(() => {
					document.documentElement.style.scrollBehavior = "smooth";
				}, 50);

				document.body.addEventListener("contextmenu", (event) => {
					event.preventDefault();
				});

				chargingListener();

				if (
					urlParams.has("mode") &&
					urlParams.get("mode") === "standalone"
				) {
					window.addEventListener("popstate", () => {
						history.pushState({}, document.title, location.href);
					});

					theme_list_section.addEventListener(
						"click",
						(e) => {
							if (e.target.tagName === "A") {
								// Try to prevent going back to splash screen
								// by filling history with links to home page
								for (let i = 0; i < 50; i++) {
									history.pushState(
										{},
										document.title,
										location.href
									);
								}
							}
						},
						false
					);
				}

				videos.forEach((video) => {
					video.addEventListener("contextmenu", (event) => {
						event.preventDefault();
					});

					video.addEventListener(
						"play",
						() => {
							//if (debugActive) addDebugList("video play");
							video.mode;
							video.textTracks[0].mode = "showing";
							if (video.requestFullscreen) {
								video.requestFullscreen();
							} else if (video.webkitRequestFullscreen) {
								/* Safari */
								video.webkitRequestFullscreen();
							} else if (video.msRequestFullscreen) {
								/* IE11 */
								video.msRequestFullscreen();
							}
						},
						false
					);

					video.addEventListener(
						"pause",
						() => {
							//if (debugActive) addDebugList("video pused");
							const timeBeforeTimeout = video.currentTime;
							setTimeout(() => {
								if (timeBeforeTimeout === video.currentTime) {
									document.exitFullscreen();
								}
							}, 1400);
						},
						false
					);
					video.addEventListener(
						"ended",
						() => {
							document.exitFullscreen();
						},
						false
					);
					video.addEventListener(
						"fullscreenchange",
						fullScreenHandler,
						false
					);
				});

				iframes.forEach((iframe) => {
					iframe.addEventListener(
						"fullscreenchange",
						fullScreenHandler,
						false
					);
				});

				if (
					big_wrapper &&
					!big_wrapper.classList.contains("inter-device")
				) {
					big_wrapper.classList.add("intern-device");
				}

				/* document.addEventListener(
					"touchstart",
					(e) => {
						if (e.touches.length > 5) {
							activateDebug();
						}
					},
					false
				); */
				/* var e = new Event("touchstart");
				e.touches = [
					{ pageX: 100, pageY: 100 },
					{ pageX: 101, pageY: 101 },
					{ pageX: 99, pageY: 99 },
					{ pageX: 102, pageY: 102 },
					{ pageX: 103, pageY: 103 },
					{ pageX: 104, pageY: 104 },
				];
				document.dispatchEvent(e); */
			},
			false
		);
	} else {
		//not tablet
		if (location.pathname === "/") {
			document.addEventListener(
				"DOMContentLoaded",
				() => {
					const backToOverview = urlParams.has("bto");

					if (!backToOverview) {
						document.querySelector(".btnv2").addEventListener(
							"click",
							() => {
								location.href = "/?bto=true";
							},
							false
						);
						showIdleOverlay();
						return;
					}

					toggleMenu(1);
				},
				false
			);
		}
	}
	if (
		location.pathname === "/" ||
		window.location.href.indexOf("baustellenkamera") > -1
	) {
		updateOnlineStatus();
		window.addEventListener("online", updateOnlineStatus, false);
		window.addEventListener("offline", updateOnlineStatus, false);
	}
	return;
}

function chargingListener() {
	try {
		navigator.getBattery().then((battery) => {
			updateChargeInfo();

			battery.addEventListener("chargingchange", updateChargeInfo, false);

			function updateChargeInfo() {
				if (battery.charging) {
					if (location.pathname !== "/") {
						location.href = "/";
						return;
					}

					document.body.classList.add("charging");

					//wenn parameter nicht gesetzt ist zeige overlay
					if (location.search.match(/(\?mode=standalone|\??)/)) {
						showIdleOverlay();
					}
					return;
				}

				document.body.classList.remove("charging");

				if (overlayActive) {
					location.href = "/?bto=true";
					toggleMenu(1);
				}
			}
		});
	} catch (_) {}
	return;
}

/* tablet erkennen */
function detectInternDevice() {
	const deviceInfos = {
		touchPoints: null,
		width: null,
		height: null,
		innerWidth: null,
		innerHeight: null,
		userAgentCopy: /Linux; Android \d+; SM-T575.*Chrome\/\d+(\.\d+)*/i,
	};

	deviceInfos.touchPoints = !!navigator.maxTouchPoints ? true : false;
	deviceInfos.width = window.screen.width;
	deviceInfos.height = window.screen.height;
	deviceInfos.innerWidth = window.innerWidth;
	deviceInfos.innerHeight = window.innerHeight;

	//check the obj props
	if (!deviceInfos.touchPoints) return false;
	if (deviceInfos.width !== 600) return false;
	if (deviceInfos.innerWidth !== 600) return false;

	if (
		deviceInfos.height !== 960 &&
		deviceInfos.height !== 864 &&
		deviceInfos.height !== 936 &&
		deviceInfos.height !== 840
	)
		return false;
	if (
		deviceInfos.innerHeight !== 960 &&
		deviceInfos.innerHeight !== 864 &&
		deviceInfos.innerHeight !== 936 &&
		deviceInfos.innerHeight !== 840
	)
		return false;

	if (!navigator.userAgent.match(deviceInfos.userAgentCopy)) return false;

	return true;
}

/* das idel overlay anzeigen */
function showIdleOverlay() {
	/* css quick fix */

	document.querySelector(".main").style.paddingBottom = "0";

	overlayActive = true;
	toggleMenu(0);
	big_wrapper.style.display = "flex";

	if (!trainStatus) {
		trainStatus = true;
		initTrainAnimations();
	}
}

/* das menu anzeigen/ausblenden */
function toggleMenu(toggleState) {
	let status;

	if (toggleState === 1) {
		document.querySelector(".main").style.paddingBottom = "8vh";
		status = "block";
	} else status = "none";

	theme_list_section.style.display = status;
	footer_detail.style.display = status;
}

function updateOnlineStatus() {
	const condition = navigator.onLine ? "online" : "offline";
	if (webcam) {
		if (condition === "online") {
			//interactive_map.hidden = false;
			webcam.parentElement.hidden = false;
		} else {
			//interactive_map.hidden = true;
			webcam.parentElement.hidden = true;
		}
	} else if (!theme_list_section) {
		if (condition === "offline") location.href = "/?bto=true";
	}
}

function fullScreenHandler() {
	if (debugActive) addDebugList("kommt in fullscreenHandler");
	const useLandscape = document.fullscreenElement !== document.body;
	//muss man für andere browser evtl noch anpassen aber geht ja hauptsächlich um das tablet das die drehung unterdrückt vom system aus.
	const orientation = useLandscape ? "landscape" : "portrait";

	screen.orientation.lock(`${orientation}-primary`);
}

function tabletInStorage() {
	return localStorage.getItem("tablet");
}

function activateDebug() {
	debugActive = true;
	document.getElementById("debugContainer").hidden = false;
	addDebugList("Debuging Activated!");
}

function addDebugList(input) {
	const debugListItem = document.createElement("li");
	debugListItem.textContent = input;
	document.getElementById("debugList").appendChild(debugListItem);
}

/* fix double click/tab to zoom */
document.addEventListener("dblclick", function (el) {
	el.preventDefault();
});

init_device_handler();
